<template>
    <dir>
        <transition name="fadeShadow">
            <div v-if="show" class="backShadow"></div>
        </transition>
        <transition>
            <div v-if="show" class="popupArea smallPop">
                <div class="areaWindow">
                    <div class="title">
                         <label class="textAverage">Permissão do Usuário</label>
                    </div>
                    <div class="tableInternalArea">
                        <table class="tableNormal textSmall">
                            <tr>
                                <th class="ResponsiveTableHead">{{user.name}}</th>
                                <th class="ResponsiveTableHead btnColumn"></th>
                            </tr>
                            <tr v-for="permission in permissionList" :key="permission.id">
                                <td>{{permission.name}}</td>
                                <td>
                                    <label class="switch">
                                        <input type="checkbox" v-model="permission.enable">
                                        <span class="slider round"></span>
                                    </label>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div class="btnArea">
                    <button @click="comeBack()" class="btn btnNormalSize btnTypeCancel">Cancelar</button>
                    <button class="btn btnNormalSize btnTypeSave" style="margin-left: 16px">Salvar</button>
                </div>
            </div>
        </transition>
    </dir>
</template>

<script>
    export default {
        components:{},
        props:{
           show: Boolean,
           user: Object
        },
        methods: {
            addUser(){

            },
            comeBack(){
                this.$emit('close')
            }
        },
        computed:{

        },
        watch:{

        },
        data(){
            return{
                userSearch: '',
                permissionList: [
                    {
                        _id: 1,
                        name: "Permissão01",
                        enable: true

                    },
                    {
                        _id: 2,
                        name: "Permissão02",
                        enable: true
                    },
                    {
                        _id: 3,
                        name: "Permissão03",
                        enable: true
                    },
                    {
                        _id: 4,
                        name: "Permissão03",
                        enable: true
                    },
                    {
                        _id: 5,
                        name: "Permissão03",
                        enable: true
                    }
                ],
            }
        },
        created(){
        }
    }
</script>

<style scoped>
    .tableInternalArea {
        position: absolute;
        bottom: 80px;
        top: 80px;
        left: 10px;
        right: 10px;
        background-color: #1e2227;
        overflow: auto;
    }
    .tableNormal td{
        padding: 15px 3px;
        text-align: center
    }
    .backShadow {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .7);
    }
    .areaWindow{
        position: absolute;
        padding: 10px 10px;
        width: 100%;
        height: 100%;
        border-radius: 5px;
        background-color: #292d32;
        box-shadow: 1px 0px 50px 20px rgba(0, 0, 0, .8);
        font-family: Helvetica, Arial, sans-serif;
    }
    .input{
        width: 400px;
        height: 40px;
        padding-left: 8px;
        background-color: #1e2227;
        border: none;
        outline: none;
        border-radius: 4px 4px 4px 4px;
        box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.6);
    }
    .form{
        display: table;
        width: 100%;
    }
    .row{
        display: table-row;
        height: 70px;
    }
    .col40{
        width: 40%;
        padding: 12px 15px;
        text-align: left;
        vertical-align: middle;
        display: table-cell;
    }
    .col60{
        width: 60%;
        padding: 12px 5px;
        text-align: left;
        display: table-cell;
    }
    .btnArea{
        margin-top: 15px;
        position: absolute;
        bottom: 15px;
        right: 12px
    }
    .imgSelectBtn{
        width: 12px;
        transform: rotate(90deg);
    }
    .inputBtn{
        float: right;
        padding: 10px;
        background: #4375EF;
        border: none;
        border-left: none;
        cursor: pointer;
        border-radius: 0 4px 4px 0;
        box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.6);
        padding: 10px 17px 2px 17px
    }
    .inputSearch{
        width: 340px;
        height: 40px;
        padding-left: 8px;
        background-color: #1e2227;
        border: none;
        outline: none;
        border-radius: 4px 0 0 4px;
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.6)
    }
    .iconSearch{
        width: 25px
    }
    .deviceArea{
        position: absolute;
        top: 100px;
        right: 10px;
        left: 10px;
        bottom: 80px;
        background-color: #1e2227;
        overflow-y: auto
    }
    @media (orientation: landscape) and (max-width: 825px){
        .smallPop{
            height: 100%;
            width: 400px;
        }
    }
    @media(max-width: 825px){
        .areaWindow{
            padding: 5px;
        }
        .tableInternalArea {
            bottom: 70px;
            top: 60px;
            left: 5px;
            right: 5px
        }
    }
</style>