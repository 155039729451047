import BackEnd, { ObjectId } from '@/sys/BackEnd/BanckEnd'
import Client from '@/sys/Model/Client/Client'
import Unity from '@/sys/Model/Unity/Unity'
import Device, { UserPermissions } from '@/sys/Model/Device/Device'
export default class UnityDAO {

    static async createUnity(unity: Unity) {
        return new Promise(async function(resolve,reject){
            try{
                await BackEnd.function().createUnity(unity);
                resolve("Unidade criada com sucesso!");
            }catch(e){
                console.log("message: ", e);
                console.log("error: ", e.error);
                reject("ERROR_126: Favor checar sua conexão com a internet.");
            }
        });
    }

    static async addUnityDevice(device: Device) {
        return new Promise(async function(resolve,reject){
            try{
                await BackEnd.function().addUnityDevice(device);
                resolve("Dispositivo adicionado com sucesso!");
            }catch(e){
                console.log("message: ", e);
                console.log("error: ", e.error);
                reject("ERROR_127: Favor checar sua conexão com a internet.");
            }
        });
    }

    static async addUnityClient(client: Client, unityList: Array<Unity>, userList: Array<UserPermissions>) {
        return new Promise(async function(resolve,reject){
            try{
                for(let i=0; i < unityList.length; i++) {
                    let unity = unityList[i];
                    if(unity.userList.length > 0) {
                        for(let j=0; j < userList.length; j++) {
                            if(!BackEnd.compareUser(userList[j], unity.userList)) {
                                unity.userList.push(userList[j]);
                            }
                        }
                    } else {
                        unity.userList = userList;
                    }
                    unity.client = client;
                    BackEnd.function().updateUnity(unity);
                    if(unity.deviceList.length > 0) {
                        BackEnd.function().updateDeviceClient(unity);
                    }
                }
                resolve("Cliente adicionado com sucesso!");
            }catch(e){
                console.log("message: ", e);
                console.log("error: ", e.error);
                reject("ERROR_128: Favor checar sua conexão com a internet.");
            }
        });
    }

    static async addUnityClientUsers(clientId: ObjectId, users: Array<UserPermissions>) {
        return new Promise(async function(resolve,reject){
            try{
                let unityList = await BackEnd.function().readUnitiesByClient(clientId);
                for(let i=0; i < unityList.length; i++) {
                    let unity = unityList[i];
                    if(unity.userList.length > 0) {
                        for(let j=0; j < users.length; j++) {
                            if(!BackEnd.compareUser(users[j], unity.userList)) {
                                unity.userList.push(users[j]);
                            }
                        }
                    } else {
                        unity.userList = users;
                    }
                    BackEnd.function().updateUnity(unity);
                }
                resolve("Usuários adicionados com sucesso!");
            }catch(e){
                console.log("message: ", e);
                console.log("error: ", e.error);
                reject("ERROR_129: Favor checar sua conexão com a internet.");
            }
        });
    }

    static async readUnity(cnpj: String) {
        return new Promise(async function(resolve,reject){
            try{
                resolve(await BackEnd.function().readUnity(cnpj));
            }catch(e){
                console.log("message: ", e);
                console.log("error: ", e.error);
                reject("ERROR_147: Favor checar sua conexão com a internet.");
            }
        });
    }

    static async readAllActiveUnities() {
        return new Promise(async function(resolve,reject){
            try{
                resolve(await BackEnd.function().readAllActiveUnities());
            }catch(e){
                console.log("message: ", e);
                console.log("error: ", e.error);
                reject("ERROR_130: Favor checar sua conexão com a internet.");
            }
        });
    }

    static async readAllAvailableUnities() {
        return new Promise(async function(resolve,reject){
            try{
                resolve(await BackEnd.function().readAllAvailableUnities());
            }catch(e){
                console.log("message: ", e);
                console.log("error: ", e.error);
                reject("ERROR_131: Favor checar sua conexão com a internet.");
            }
        });
    }

    static async updateUnity(unity: Unity) {
        return new Promise(async function(resolve,reject){
            try{
                await BackEnd.function().updateUnity(unity);
                resolve("Unidade atualizada com sucesso!");
            }catch(e){
                console.log("message: ", e);
                console.log("error: ", e.error);
                reject("ERROR_132: Favor checar sua conexão com a internet.");
            }
        });
    }

    static async removeUnityClient(unityList: Array<Unity>) {
        return new Promise(async function(resolve,reject){
            try{
                await BackEnd.function().removeUnityClient(unityList);
                resolve("Cliente removido com sucesso!");
            }catch(e){
                console.log("message: ", e);
                console.log("error: ", e.error);
                reject("ERROR_137: Favor checar sua conexão com a internet.");
            }
        });
    }

    static async removeUnityDevice(device: Device, cnpj: String) {
        return new Promise(async function(resolve,reject){
            try{
                await BackEnd.function().removeUnityDevice(device, cnpj);
                resolve("Dispositivo removido com sucesso!");
            }catch(e){
                console.log("message: ", e);
                console.log("error: ", e.error);
                reject("ERROR_138: Favor checar sua conexão com a internet.");
            }
        });
    }

    static async removeUnityClientUsers(clientId: ObjectId, userList: Array<UserPermissions>) {
        return new Promise(async function(resolve,reject){
            try{
                let unityList = await BackEnd.function().readUnitiesByClient(clientId);
                for(let i=0; i < unityList.length; i++) {
                    let unity = unityList[i];
                    let users = [];
                    if(unity.userList.length > 0) {
                        for(let j=0; j < unity.userList.length; j++) {
                            let user = unity.userList[j];
                            if(!user.addFromClient || (user.addFromClient && !BackEnd.compareUser(user, userList))) {
                                users.push(user);
                            }
                        }
                        unity.userList = users;
                        BackEnd.function().updateUnity(unity);
                    }
                }
                resolve("Usuários removidos com sucesso!");
            }catch(e){
                console.log("message: ", e);
                console.log("error: ", e.error);
                reject("ERROR_140: Favor checar sua conexão com a internet.");
            }
        });
    }
}

export {UnityDAO}