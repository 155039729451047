import BackEnd from '@/sys/BackEnd/BanckEnd'
import Client from '@/sys/Model/Client/Client'
import Unity from '@/sys/Model/Unity/Unity'
export default class ClientDAO {

    static async createClient(client: Client) {
        return new Promise(async function(resolve,reject){
            try{
                resolve(await BackEnd.function().createClient(client));
            }catch(e){
                console.log("message: ", e);
                console.log("error: ", e.error);
                reject("ERROR_101: Favor checar sua conexão com a internet.");
            }
        });
    }

    static async addClientUnity(unity: Unity) {
        return new Promise(async function(resolve,reject){
            try{
                await BackEnd.function().addClientUnity(unity);
                resolve("Unidade adicionada com sucesso!");
            }catch(e){
                console.log("message: ", e);
                console.log("error: ", e.error);
                reject("ERROR_102: Favor checar sua conexão com a internet.");
            }
        });
    }

    static async readClientByName(name: String) {
        return new Promise(async function(resolve,reject){
            try{
                resolve(await BackEnd.function().readClientByName(name));
            }catch(e){
                console.log("message: ", e);
                console.log("error: ", e.error);
                reject("ERROR_103: Favor checar sua conexão com a internet.");
            }
        });
    }

    static async readClientByCNPJ(cnpj: String) {
        return new Promise(async function(resolve,reject){
            try{
                resolve(await BackEnd.function().readClientByCNPJ(cnpj));
            }catch(e){
                console.log("message: ", e);
                console.log("error: ", e.error);
                reject("ERROR_104: Favor checar sua conexão com a internet.");
            }
        });
    }

    static async readAllActiveClients() {
        return new Promise(async function(resolve,reject){
            try{
                resolve(await BackEnd.function().readAllActiveClients());
            }catch(e){
                console.log("message: ", e);
                console.log("error: ", e.error);
                reject("ERROR_105: Favor checar sua conexão com a internet.");
            }
        });
    }

    static async updateClient(client: Client) {
        return new Promise(async function(resolve,reject){
            try{
                await BackEnd.function().updateClient(client);
                resolve("Cliente atualizado com sucesso!");
            }catch(e){
                console.log("message: ", e);
                console.log("error: ", e.error);
                reject("ERROR_106: Favor checar sua conexão com a internet.");
            }
        });
    }

    static async removeClientUnity(unity: Unity) {
        return new Promise(async function(resolve,reject){
            try{
                await BackEnd.function().removeClientUnity(unity);
                resolve("Unidade removida com sucesso!");
            }catch(e){
                console.log("message: ", e);
                console.log("error: ", e.error);
                reject("ERROR_109: Favor checar sua conexão com a internet.");
            }
        });
    }
}

export {ClientDAO}