<template>
    <dir>
        <transition name="fadeShadow">
            <div @click="comeBack()" class="backShadow"></div>
        </transition>
        <transition>
            <div class="popupArea normalPopup">
                <div class="areaWindow">
                    <div class="title">
                        <label class="textAverage">Lista de {{title}}</label>
                    </div>
                    <div class="inputContainer">
                        <input class="inputSearch textSmall" type="text" placeholder="Pesquisar" v-model="search">
                        <img class="searchIcon" src="@/assets/icons/outros/search2.svg" />
                    </div>
                    <div v-if="title == 'Clientes'" class="tableInternalArea">
                        <table class="tableNormal textSmall">
                            <tr>
                                <th class="ResponsiveTableHead">{{title.slice(0, -1)}}</th>
                                <th class="ResponsiveTableHead">CNPJ</th>
                            </tr>
                            <tr v-for="(item, index) in listFilter" :key="index" @click="select(item)">
                                <td>{{item.name}}</td>
                                <td>{{item.cnpj}}</td>
                            </tr>
                        </table>
                        <div v-if="!list" class="loaderPosition">
                            <div v-if="listLoading" class="Loader"/>
                        </div>
                    </div>
                    <div v-else-if="title == 'Unidades'" class="tableInternalArea">
                        <table class="tableNormal textSmall">
                            <tr>
                                <th class="ResponsiveTableHead">{{title.slice(0, -1)}}</th>
                                <th class="ResponsiveTableHead">CNPJ</th>
                                <th class="ResponsiveTableHead">Cliente</th>
                            </tr>
                            <tr v-for="(item, index) in listFilter" :key="index" @click="select(item)">
                                <td>{{item.name}}</td>
                                <td>{{item.cnpj}}</td>
                                <td v-if="item.client != null">{{item.client.name}}</td>
                                <td v-else>{{'-'}}</td>
                            </tr>
                        </table>
                        <div v-if="!list" class="loaderPosition">
                            <div v-if="listLoading" class="Loader"/>
                        </div>
                    </div>
                    <div v-else class="tableInternalArea">
                        <table class="tableNormal textSmall">
                            <tr>
                                <th class="ResponsiveTableHead">Hardware ID</th>
                            </tr>
                            <tr v-for="(item, index) in listFilter" :key="index" @click="select(item.hardwareId)">
                                <td>{{item.hardwareId}}</td>
                            </tr>
                        </table>
                        <div v-if="!list" class="loaderPosition">
                            <div v-if="listLoading" class="Loader"/>
                        </div>
                    </div>
                </div>
                <div class="btnArea">
                    <button @click="comeBack()" class="btn btnNormalSize btnTypeCancel">Cancelar</button>
                </div>
            </div>
        </transition>
    </dir>
</template>

<script>
    export default {
        components:{},
        props:{
            title: String,
            list: Array
        },
        methods: {
            select(item){
                this.$emit('selected', item)
            },
            comeBack(){
                this.$emit('close')
            }
        },
        computed:{
            listFilter(){
                if(this.list != null){
                    if(this.title == 'Clientes'){
                        return this.list.filter(client => {
                            return client.name.toLowerCase().includes(this.search.toLowerCase()) ||
                                client.cnpj.toLowerCase().includes(this.search.toLowerCase())
                        })
                    }else if (this.title == 'Unidades'){
                        return this.list.filter(unity => {
                            return unity.name.toLowerCase().includes(this.search.toLowerCase()) ||
                                unity.cnpj.toLowerCase().includes(this.search.toLowerCase()) ||
                                unity.client.name.toLowerCase().includes(this.search.toLowerCase())
                        })
                    }else {
                        return this.list.filter(device => {
                            return device.hardwareId.toLowerCase().includes(this.search.toLowerCase())
                        })
                    }
                }
            }
        },
        data(){
            return{
                search: '',
                listLoading: true
            }
        },
        created(){}
    }
</script>

<style scoped>
    .tableInternalArea {
        position: absolute;
        bottom: 80px;
        top: 130px;
        left: 10px;
        right: 10px;
        background-color: #1e2227;
        overflow: auto;
    }
    .tableNormal td{
        padding: 15px 3px;
        text-align: center;
        cursor: pointer;
    }
    .backShadow {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .7);
    }
    .areaWindow{
        position: absolute;
        padding: 10px 10px;
        width: 100%;
        height: 100%;
        border-radius: 5px;
        background-color: #292d32;
        box-shadow: 1px 0px 50px 20px rgba(0, 0, 0, .8);
        font-family: Helvetica, Arial, sans-serif;
    }
    .input{
        width: 400px;
        height: 40px;
        padding-left: 8px;
        background-color: #1e2227;
        border: none;
        outline: none;
        border-radius: 4px 4px 4px 4px;
        box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.6);
    }
    .formArea{
        position: absolute;
        top: 70px;
        width: 685px;
        right: 0;
        left: 0;
        margin-left: auto;
        margin-right: auto;
        height: 200px;
    }
    .form{
        display: table;
        width: 100%;
    }
    .row{
        display: table-row;
        height: 70px;
    }
    .col40{
        width: 40%;
        padding: 12px 15px;
        text-align: left;
        vertical-align: middle;
        display: table-cell;
    }
    .col60{
        width: 60%;
        padding: 12px 5px;
        text-align: left;
        display: table-cell;
    }
    .btnArea{
        margin-top: 15px;
        position: absolute;
        bottom: 15px;
        right: 12px
    }
    .imgSelectBtn{
        width: 12px;
        transform: rotate(90deg);
    }
    .inputBtn{
        float: right;
        padding: 10px;
        background: #4375EF;
        border: none;
        border-left: none;
        cursor: pointer;
        border-radius: 0 4px 4px 0;
        box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.6);
        padding: 10px 17px 2px 17px
    }
    .iconSearch{
        width: 25px
    }
    .deviceArea{
        position: absolute;
        top: 100px;
        right: 10px;
        left: 10px;
        bottom: 80px;
        background-color: #1e2227;
        overflow-y: auto
    }
    @media (orientation: landscape) and (max-width: 825px) {
        .normalPopup{
            height: 100%;
            width: 400px;
        }
    }
    @media(max-width: 825px){
        .areaWindow{
            padding: 5px;
        }
        .tableInternalArea {
            bottom: 70px;
            top: 100px;
            left: 5px;
            right: 5px
        }
        .inputSearch{
            margin-top: 0;
        }
        .searchIcon{
            top: 8px;
        }
    }
</style>